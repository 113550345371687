* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent overflow */
}

body {
  overflow: hidden;
  overflow-y: auto; /* Allow vertical scrolling if necessary */

}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-size: 100px 100px;
  align-items: center;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./Components/banner.png'); /* Path to your background image */
  background-size: cover;
  background-repeat: no-repeat; /* Repeat the background image */
  opacity: 0.2; /* Make the background image faded */
  z-index: -1; /* Place the background behind other content */
}

.App::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(165, 42, 42, 0.2); /* Translucent brownish overlay */
  z-index: -1; /* Place the overlay behind other content but above the background image */
}

h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  margin-top: 50px;
  margin-bottom: -50px;
  font-weight: bold;
  font-size: 140px;
  text-align: center;
  color: #6f4e36; /* $hue equivalent */
  text-shadow: 2px 2px 0 #d2bdad; /* $hue-dark equivalent */
}

.goo {
  position: relative;
  top: 20%;
  width: 150px;
  margin: auto;
  transform: translateY(-30%);
  filter: url(#goo);
}

.drop {
  opacity: 0;
  width: 30px;
  height: 40px;
  background: #6f4e36; /* Adjusted hue */
  border-radius: 1000px;
  position: absolute;
  animation: drip 8s infinite;
  border: 2px solid #d2bdad; /* $hue-dark equivalent */
  border-top: none;
  top: 85px;
  left: 35px;
}

.drop:nth-child(2) {
  left: 65px;
  animation-delay: 3s;
}

.drop:nth-child(3) {
  left: 198px;
  animation-delay: 2s;
}

.drop:nth-child(4) {
  left: 101.5px;
  animation-delay: 4s;
}

.drop:nth-child(5) {
  left: 254.5px;
  animation-delay: 1.5s;
}

.drop:nth-child(6) {
  left: 59px;
  animation-delay: 5s;
}

@keyframes drip {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  
  17.5% {
    opacity: 1;
    transform: translateY(15px);
  }
  
  25% {
    transform: translateY(55vh);
  }
  
  50% {
    transform: translateY(55vh);
  }
}


.App-header-container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin-top: 10px; /* Top margin to reflect */
  flex-direction: row;
  max-width: 60vh;
}

.App-header {
  background-color: #6f4e36;
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  color: white;
  height: 70px;
  margin-top: 130px;
  width: 300px;
  margin: 0 20px;
  border-radius: 7px;
  margin-left: 10px;
}

.App-header-div {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 10px;
  text-decoration: none;
}

.App-header-link {
  background-color: #d2bdad;
  border-radius: 100px;
  box-shadow: 0px 4px 6px rgba(146, 97, 93, 0.3); /* Add box-shadow with opacity */
  color: brown;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  font-weight: 600;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

a {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit color to prevent default link color */
}

.App-header-link:hover {
  box-shadow: 0px 4px 8px rgba(178, 165, 147, 0.5); /* Add hover effect with opacity */
  transform: scale(1.07) rotate(-1deg);
  font-weight: bold;
}
.App-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-meme {
  max-width: 100%;
  height: 350px;
  margin-top: 30px;
  width: 300px;
  border: solid #191712;
  border-radius: 20px;
  justify-content: center;
}

.spaced-text {
  display: block;
  margin-top: 20px; /* Adjust the margin as needed */
}

.description{
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-size: 28px;
  color: #3c1f10;
}

.links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.link-prop {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.link-prop img {
  width: 40px; /* Set the size of the icons */
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header-image {
  width: 70px; /* Set the width of the image */
  height: 70px; /* Maintain aspect ratio */
  margin-left: 20px; /* Space between the image and the header */
  border: solid 2px;
  border-radius: 300px;
}

@media (max-width: 768px) {

  
  .description {
    font-size: 24px;
  }

  .App-meme {
    width: 250px;
    height: 270px;
  }

}